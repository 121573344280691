<template>
  <section class="admin-price-tool">
    <v-container class="custom-container">
      <div class="textbox">
        <div class="left">
          <h1>{{ $t('langkey.price-tool') }}</h1>
          <p class="description">
            {{ $t('langkey.admin-price-tool-description') }}
          </p>
        </div>
        <div class="right">
          <v-btn
            plain
            class="create-new-price__button"
            @click="createNewPrice"
          >
            <icon-add-two />
          </v-btn>
        </div>
      </div>

      <v-card
        rounded="sm"
        class="price-tool"
      >
        <v-card-title class="card__title">
          <v-btn
            depressed
            plain
            class="delete-all__button icon"
          >
            {{ $t('langkey.delete-all') }} <icon-bin />
          </v-btn>
        </v-card-title>
        <v-data-table
          class="primary-style"
          :headers="tableFieldPriceTool"
          :items="[]"
          hide-default-footer
          disable-sort
        >
          <template
            v-for="(field, index) in tableFieldPriceTool"
            v-slot:[`header.${field.value}`]="{ header }"
          >
            <span :key="`${field.value}${index}`">{{ $t(header.text) }}</span>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <v-dialog
      v-model="createNewPriceDialog"
      max-width="652"
    >
      <v-card class="dialog create-new-price">
        <v-card-title>
          <h5 class="text">
            {{ $t('langkey.create-new-price-calculation') }}
          </h5>
          <v-btn
            icon
            plain
            @click="createNewPriceDialog = false"
          >
            <icon-close size="18" />
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="row-details">
            <p class="label">
              {{ $t('langkey.article-group') }} :
            </p>
            <v-select
              v-model="priceData.articleGroup"
              :items="items"
              solo
              hide-details="auto"
            >
              <template #append>
                <icon-caret />
              </template>
            </v-select>
          </div>
          <div class="row-details">
            <p class="label">
              {{ $t('langkey.price-from') }} :
            </p>
            <v-text-field
              :label="`${$t('langkey.enter-price-from')}`"
              solo
              hide-details="auto"
            />
          </div>
          <div class="row-details">
            <p class="label">
              {{ $t('langkey.price-to') }} :
            </p>
            <v-text-field
              :label="`${$t('langkey.enter-price-to')}`"
              solo
              hide-details="auto"
            />
          </div>
          <div class="row-details">
            <p class="label">
              {{ $t('langkey.diameter-from') }} :
            </p>
            <v-text-field
              :label="`${$t('langkey.enter-diameter-from')}`"
              solo
              hide-details="auto"
            />
          </div>
          <div class="row-details diameter-to">
            <p class="label">
              {{ $t('langkey.diameter-to') }} :
            </p>
            <v-text-field
              :label="`${$t('langkey.enter-diameter-to')}`"
              solo
              hide-details="auto"
            />
          </div>
          <div class="note">
            {{ $t('langkey.note-surcharges') }}
          </div>
          <div class="row-details">
            <p class="label">
              {{ $t('langkey.surcharge') }} % :
            </p>
            <v-text-field
              value="0"
              solo
              hide-details="auto"
            >
              <template #append>
                %
              </template>
            </v-text-field>
          </div>
          <div class="row-details">
            <p class="label">
              {{ $t('langkey.surcharge') }} € :
            </p>
            <v-text-field
              value="0"
              solo
              hide-details="auto"
            >
              <template #append>
                €
              </template>
            </v-text-field>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            outlined
            plain
            @click="createNewPriceDialog = false"
          >
            {{ $t('langkey.cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            @click="onSavePriceCalculation"
          >
            {{ $t('langkey.save-price-calculation') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<style lang="scss">
@import "@/styles/pages/admin-price-tool.scss";
</style>

<script>
import IconAddTwo from '@/components/icons/IconAddTwo';
import IconBin from '@/components/icons/IconBin';
import IconCaret from '@/components/icons/IconCaret';
import { tableFieldPriceTool } from './configs.js';

export default {
  components: {
    IconAddTwo,
    IconBin,
    IconCaret,
  },
  data() {
    return {
      createNewPriceDialog: false,
      priceData: {
        articleGroup: '',
      },
      items: ['Agriculture', 'Foo', 'Bar', 'Fizz', 'Buzz'],
    };
  },
  computed: {
    tableFieldPriceTool() {
      return tableFieldPriceTool;
    },
  },
  mounted() {
    this.priceData.articleGroup = this.items[0];
  },
  methods: {
    createNewPrice() {
      this.createNewPriceDialog = true;
    },
    onSavePriceCalculation() {
      this.createNewPriceDialog = false;
    },
  },
};
</script>