export const tableFieldPriceTool = [
  { text: 'langkey.article-type', align: 'center', value: 'articleType' },
  { text: 'langkey.article-group', align: 'center', value: 'articleGroup' },
  { text: 'langkey.retread', align: 'center', value: 'retread' },
  { text: 'langkey.diameter-from', align: 'center', value: 'diameterFrom' },
  { text: 'langkey.diameter-to', align: 'center', value: 'diameterTo' },
  { text: 'langkey.price-from', align: 'center', value: 'priceFrom' },
  { text: 'langkey.price-to', align: 'center', value: 'priceTo' },
  { text: '%', align: 'center', value: 'percent' },
  { text: '€', align: 'center', value: 'price' },
  { text: 'langkey.promotions', align: 'center', value: 'promotions' },
  { text: '', align: 'center', value: 'edit' },
  { text: '', align: 'center', value: 'delete' },
];